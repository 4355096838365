import { SignUp } from '@clerk/remix'

import { Card, CardContent } from '~/components/ui/card'

export const ClerkLayout = {
	helpPageUrl: 'mailto:support@doreveal.com',
	termsPageUrl: 'https://doreveal.com/terms-of-service',
	privacyPageUrl: 'https://doreveal.com/privacy-policy',
	logoImageUrl: '/static-assets/square-logo.svg',
	logoLinkUrl: '/',
}

export default function SignUpPage() {
	return (
		<>
			<div className="p-4 bg-indigo-950 top-0 left-0 w-full mb-7">
				<h2 className="text-xl font-sm text-center text-indigo-200">
					Start Your Reveal Journey.
				</h2>
			</div>
			<div className="container mx-auto px-4 py-8">
				<div className="flex flex-col lg:flex-row justify-center items-start space-y-4 lg:space-y-0 lg:space-x-8">
					<div className="w-full lg:w-1/2 max-w-md">
						<SignUp
							appearance={{
								layout: ClerkLayout,
							}}
						/>
					</div>
					<div className="w-full lg:w-1/2 max-w-md">
						<WhyConsiderReveal />
					</div>
				</div>
			</div>
		</>
	)
}

const WhyConsiderReveal = () => {
	const features = [
		'Privacy-friendly AI.',
		'Transcribes and translates.',
		'Reviews transcripts to find answers to research questions.',
		'Identifies emerging themes.',
		'Tests hypotheses.',
		'Answers any additional questions that come up during the analysis.',
		'Compares all interviews to find similarities and differences.',
		'Finds verbatim quots.',
		'And more...',
	]

	return (
		<Card className="p-6 border-0 drop-shadow-none">
			<CardContent>
				<h2 className="text-xl font-semibold mb-4 text-slate-700">Why Consider Reveal?</h2>
				<ul className="space-y-2 mb-4 p-3">
					{features.map((feature, index) => (
						<li key={index} className="list-disc">
							{feature}
						</li>
					))}
				</ul>
				<p className="text-md">
					Your trial allows analysis of{' '}
					<strong className="font-medium">up to 3 interviews</strong>.
				</p>
			</CardContent>
		</Card>
	)
}
